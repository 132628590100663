import SmoothScroll from 'smooth-scroll';
import AOS from 'aos';

const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 300,
    offset: function (anchor, navigation) {
        if (screen.width <= 640) {
            return 0;
        } else {
            return 0;
        }
    },
    easing: 'easeInOutQuint',
    updateURL: false,
    popstate: false
});

// アニメーション設定
AOS.init({
    delay: 0,
    offset: 200,
    once: true,
    duration: 800,
});
